import React from 'react'

import avatar from '../assets/images/avatar.jpg'
import Footer from './Footer'

class Header extends React.Component {
  render() {
    return (
      <header id="header">
        <div className="inner">
          <a href="https://github.com/oak-wildwood" className="image avatar">
            <img src={avatar} alt="avatar" />
          </a>
          <h1>
            <strong>Oak Cooper&nbsp;</strong>
            <br />
            <span> - a fullstack software craftsman </span>
            <br />
            with a focus on usability <br />
            and front end development.
            <br />
          </h1>
        </div>
        <Footer />
      </header>
    )
  }
}

export default Header
